<template>
  <div class="text-center ma-2">
    <v-snackbar v-model="show" :color="color">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { GLOBAL_SNACKBAR_EVENT_BUS } from "./js/globalSnackbarEventbus";

export default {
  data: () => ({
    show: false,
    text: "",
    color: "black"
  }),
  methods: {
    showSnackbar() {
      GLOBAL_SNACKBAR_EVENT_BUS.$off("showSnackbar");
      GLOBAL_SNACKBAR_EVENT_BUS.$on("showSnackbar", value => {
        this.color = value.color;
        this.text = value.text;
        this.show = value.show;
      });
    }
  },
  mounted() {
    this.showSnackbar();
  }
};
</script>
